<template>
    <div class="messageWrapper" :class="{ 'myMessage' : isMyMessage }">
        <div class="messageGroup">
            <div class="message">{{ message.message }}</div>
            <div class="date" v-if="message.date">{{ messageDate }}</div>
        </div>
        <div class="messageDetails" v-if="!isMyMessage">
            <span v-if="message.driver">{{ message.driver.name }}</span>
            <span v-if="message.dispatcher">{{ message.dispatcher.name }}</span>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex'
    export default {
        props: ['message'],
        data() {
            return {
                
            }
        },
        computed: {
            ...mapGetters(['user']),
            isMyMessage() {
                if(this.message.driver && this.message.driver._id === this.user._id) return true;
                return false;
            },
            messageDate() {
                if(!this.message.date) return;
                let date = moment(this.message.date);
                return date.format("Do MMM") + ' ' + date.format("HH:mm");
            }
        }
    }
</script>

<style lang="scss" scoped>
.messageWrapper {
    display: grid;
    row-gap: 3px;
    width: 100%;

    .message {
        padding: 5px 10px;
        background: $bg;
        border-radius: 10px 10px 10px 0;
        font-size: 13px;
        border: 1px solid $borderColor;
        max-width: calc(100% - 100px);
    }

    .messageGroup {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: stretch;
        justify-items: stretch;
        .date {
            font-size: 10px;
            margin-left: 10px;
        }
    }

    .messageDetails {
        text-align: left;
        font-size: 12px;
    }

    &.myMessage {
        
        .message {
            border-radius: 10px 10px 0 10px;
            background: $buttonColor;
            color: #fff;
            border: 0;
        }
        .messageDetails {
            text-align: right;
        }
        .messageGroup {
            flex-direction: row-reverse;
            .date {
                margin-left: 0;
                margin-right: 10px;
            }
        }
    }
}

</style>