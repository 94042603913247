<template>
<div class="loadDetailsGroup">

    <GMaps :origin="load && load.origin ? load.origin : null" :destination="load && load.origin ? load.destination : null"/>

    <div class="loadDetailsGrid" v-if="loadID && load">
        <div class="originDestination">


            <div class="locationGroup" v-if="load.stops">
                <div class="name">
                    <div>Trip Details</div>
                </div>
                
                <div class="value listOfStops">
                    <div :key="key" v-for="(stop, key) of load.stops" class="stop">
                        <div class="stopType">{{ stop.type }} {{ stop.key }} </div>
                        <div class="stopName">{{ stop.value.name }}</div>
                        <div class="stopAddress">{{ stop.value.addressLine1 }}</div>
                        <div class="stopAddress">{{ stop.value.addressLine2 }}</div>
                        <div class="details">
                            <div v-if="stop.date">{{ stop.date.string }}</div>
                            <div v-if="stop.pickupNumber">PU#: {{ stop.pickupNumber }}</div>
                            <div v-if="stop.comments" class="comments"><strong>Comments:</strong> {{ stop.comments }}</div>
                        </div>
                    </div> 
                </div>
            </div>
            

            <div class="locationGroup">
                <div class="name">
                    <div>Broker</div>
                </div>
                <div class="value">{{ load.broker }}</div>
            </div>

            <div class="locationGroup">
                <div class="name">Broker name</div>
                <div class="value">{{ load.contactName }}</div>
            </div>

            <div class="locationGroup" v-if="load.contactPhone">
                <div class="name">Broker phone</div>
                <div class="value">{{ load.contactPhone }}</div>
            </div>

            <div class="locationGroup" v-if="load.contactEmail">
                <div class="name">Broker email address</div>
                <div class="value">{{ load.contactEmail }}</div>
            </div>

        </div>

        <div class="otherDetails">

            

            <div class="locationGroup">
                <div class="name">
                    <div>Picking up on</div>
                </div>
                <div class="value">{{ pickup }}</div>
            </div>


            <div class="locationGroup">
                <div class="name">Documents</div>
                <div class="value" style="display: grid; row-gap: 15px;">
                    <div class="documents" v-if="load.documents.length > 0">
                        <div class="document" :key="key" v-for="(doc, key) of load.documents" @click="openFile(doc)">
                            <div class="docName">{{ doc.type }}</div>
                            <div class="delete"><button @click.stop="deleteDocument(doc._id)"><i class="fa-solid fa-trash-can"></i></button></div>
                        </div>
                    </div>
                    <div v-else>No documents uploaded</div>
                    <button class="default small" @click="uploadDocument">Upload document</button>
                </div>
            </div>


            <div class="locationGroup">
                <div class="name">Delivered by</div>
                <div class="value">{{ load.deliveryDriver[0] ? load.deliveryDriver[0].name : 'No delivery driver assigned' }}</div>
            </div>


            <div class="locationGroup">
                <div class="name">Picked up by</div>
                <div class="value">{{ load.pickupDriver[0] ? load.pickupDriver[0].name : 'No delivery driver assigned' }}</div>
            </div>

            



            <div class="locationGroup">
                <div class="name">Notes</div>
                <div class="value" v-if="load.notes">{{ load.notes }}</div>
                <div class="value" v-else>No notes provided.</div>
            </div>

            <div class="locationGroup" v-if="load.dispatcher && load.dispatcher[0]">
                <div class="name">Dispatcher</div>
                <div class="value">

                    <div class="dispatcherChatProfile">

                        <div class="dispatcher">
                            <div class="image" :style="`background-image: url('${load.dispatcher[0].profileImage}')`"></div>
                            <div class="dispatcherDetails">
                                <div class="dispatcherName">{{ load.dispatcher[0].name }}</div>
                                <div class="dispatcherPosition">{{ load.dispatcher[0].position }}</div>
                            </div>
                        </div>

                        <button class="default chatButton" @click="openLoadChat"><i class="fa-regular fa-comments"></i></button>

                    </div>

                </div>
            </div>

        </div>
        
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import GMaps from './GMaps.vue'


import UploadDocumentModal from './Loads/UploadDocument.vue'
import LoadChat from './LoadsChat/Chat.vue'

    export default {
        components: {
            GMaps
        },
        data() {
            return {
                load: null,
                mapLoading: true,
            }
        },
        mounted() {

        },
        computed: {
            ...mapGetters(['loadManager', 'users', 'carriers', 'user']),
            loadID() {
                return this.loadManager.viewLoad;
            },
            apiURL() {
                return process.env.VUE_APP_BOARD_API_URL || 'http://localhost:4000';
            },
            pickup() {
                return moment.utc(this.load.date, "MM/DD/YYYY").format("dddd, D MMM, YYYY");
            }
        },
        methods: {
            openFile(doc) {
                let url = `${process.env.VUE_APP_BOARD_API_URL || ''}/storage/files/${doc._id}`;
                window.open(url, '_blank');
            },
            openLoadChat() {
                this.$showModalSimple(LoadChat, 'Chat', {
                    loadId: this.load._id
                });
            },
            deleteDocument(docId) {
                let confirmDelete = confirm("Do you really wish to delete this document?");
                if(!confirmDelete) return;

                this.ajax('deleteDocument', {
                    url: `/loadDocuments/${this.load._id}/${docId}`,
                    method: 'DELETE'
                }, (err, body) => {
                    if(err) {
                        alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.getLoadDetails();
                });
            },
            uploadDocument() {
                this.$showModalSimple(UploadDocumentModal, 'Upload document', {
                    loadId: this.load._id
                }, (data) => {
                    if(data) {
                        this.getLoadDetails();
                    }
                });
            },
            copyDispatch(e) {
                if(!this.load.stops) return;
                e.target.innerText = 'Copying';
                let text = '';
                let i = 0;
                for(const stop of this.load.stops) {
                    text += `${(stop.type)} ${stop.key}\n\n`;
                    if(stop.type == 'pick' && stop.pickupNumber) text += `PU #: ${stop.pickupNumber}\n\n`;
                    text += `${stop.value.name}\n`;
                    text += `${stop.value.addressLine1}\n`;
                    text += `${stop.value.addressLine2} ${stop.value.zip}\n`;
                    i++;
                    if(this.load.stops[i]) {
                        text += '\n\n\n';
                    }
                }
                text = text.toUpperCase();
                this.copyTextToClipboard(text);
                e.target.innerText = 'Copied';
                setTimeout(() => {
                    e.target.innerText = 'Copy Dispatch';
                }, 1000);
            },
            getLoadDetails() {
                if(!this.loadID) return;
                this.ajax('getLoadDetails', {
                    method: 'GET',
                    url: `/load/${this.loadID}`
                }, (err, body) => {
                    if(err) {
                        this.$store.state.loadManager.viewLoad = null;
                        return;
                    }
                    this.load = body;
                });
            }
        },
        watch: {
            loadID() {
                this.getLoadDetails();
            }
        }
    }
</script>

<style lang="scss" scoped>

.dispatcherChatProfile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .chatButton {
        width: 44px;
        height: 44px;
        padding: 0;
        background: $success;
        border-radius: 12px;
        font-size: 16px;
    }
}

.driverSelector {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 40px;
    background: $bgSecondary;
    overflow: hidden;
    border-radius: $borderRadius;
    box-shadow: $boxShadow;
    border: 1px solid $borderColor;
    select, button {
        height: 40px;
    }
    select {
        border: 0;
        background: transparent;
        padding: 0 10px;
        text-transform: uppercase;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        cursor: pointer;
        color: $text;
    }
    button {
        border: 0;
        background: transparent;
        border-left: 1px solid $borderColor;
        color: $buttonColor;
        padding: 0;
        font-size: 12px;
    }
}



.documents {
    background: $bgSecondary;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    .document {
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        cursor: pointer;
        border-bottom: 1px solid $borderColor;
        &:last-child {
            border: 0;
        }
        .docName {
            text-transform: uppercase;
            font-size: 12px;
        }
        .delete {
            display: none;
            button {
                border: 0;
                border-bottom: 3px solid $borderColor;
                width: 24px;
                height: 24px;
                border-radius: 5px;
                font-size: 10px;
                color: $text;
                background: $buttonColor2;
                color: #C0392B;
            }
        }
        &:hover {
            .delete {
                display: block;
            }
        }
    }
}

.actionBar {
    display: grid;
    grid-template-columns: repeat(5, 44px);
    column-gap: 10px;
    .btn {
        width: 44px;
        height: 44px;
        color: $buttonColor;
        background: $bgSecondary;
        border: 0;
        border-radius: 10px;
        box-shadow: $boxShadow;
        font-size: 14px;
        transition: ease 0.3s;
        border: 1px solid $borderColor;
        &.edit {
            background: #F7DC6F;
            color: #3E3E3E;
        }
        &.delete {
            background: #CD6155;
            color: #fff;
        }
        &:hover {
            transform: scale(1.05);
        }
    }
}

.loadDetailsGrid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    align-items: start;
    padding: 0 30px;
}

.originDestination {
    display: grid;
    row-gap: 30px;
    padding-right: 30px;
}

.locationGroup {
    display: grid;
    row-gap: 10px;
    .name {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.otherDetails {
    border-left: 1px solid $borderColor;
    padding-left: 30px;
    display: grid;
    row-gap: 30px;
}

.loadRate {
    color: #1abc9c;
    font-weight: bold;
    font-size: 26px;
}

.rowGroup {
    display: grid;
    row-gap: 5px;
    .name {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
    }
}

.rateConfirmation {
    display: flex;
    align-items: center;
    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 54px;
        height: 54px;
        box-shadow: $boxShadow;
        border-radius: 10px;
        margin-right: 20px;
        font-size: 18px;
        color: $buttonColor;
        background: $bgSecondary;
        border: 1px solid $borderColor;
        transition: ease 0.3s;
    }
    &:hover {
        .icon {
            transform: scale(1.1);
        }
    }
}

.dispatcher {
    height: 44px;
    display: grid;
    grid-template-columns: 44px minmax(0, 1fr);
    column-gap: 20px;
    align-items: center;
    .image {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: #fff;
        background-position: center;
        background-size: cover;
        border: 2px solid $buttonColor;
    }
    .dispatcherDetails {
        display: grid;
        row-gap: 3px;
        .dispatcherName {
            font-weight: bold;
            font-size: 14px;
            text-transform: uppercase;
        }
        .dispatcherPosition {
            font-size: 12px;
            text-transform: uppercase;
        }
    }
}

.editForm {
    box-shadow: $boxShadow;
    padding: 20px;
    border-radius: 5px;
    background: $bgSecondary;
    display: grid;
    row-gap: 20px;
    .cancellHolder {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }
}

.loadDetailsGroup {
    display: grid;
    row-gap: 30px;
}

.mapHolder {
    width: 100%;
    padding-top: 50%;
    position: relative;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
    .mapLoading {
        background: $bgSecondary;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $borderColor;
        border-top: 1px solid $borderColor;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 17px;
        opacity: 1;
        transition: ease 0.5s;
        transition-delay: 0.2s;
        &.hidden {
            opacity: 0;
            pointer-events: none;
        }
    }
}

.driver {
    display: grid;
    row-gap: 20px;
    padding: 0 10px;
    border-left: 2px solid $buttonColor;
    .driverName {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
    }
}


@media only screen and (max-width: 550px) {
    .loadDetailsGrid {
        grid-template-columns: minmax(0, 1fr);
        row-gap: 30px;
    }
    .otherDetails {
        border: 0;
        padding: 0;
    }
    
}


.stop {
    position: relative;
    padding: 17px 10px 0 30px;
    text-transform: uppercase;
    font-size: 13px;
    .details {
        margin-top: 10px;
        opacity: 0.75;
        display: grid;
        row-gap: 5px;
        .comments {
            margin-top: 5px;
            text-transform: none;
        }
    }
    &::before {
        position: absolute;
        top: 20px;
        left: 0;
        content: '';
        width: 10px;
        height: 10px;
        background: $buttonColor;
    }
    &::after {
        position: absolute;
        top: 35px;
        left: 5px;
        bottom: -15px;
        transform: translateX(-50%);
        content: '';
        width: 1px;
        background: linear-gradient(180deg, $bg 0%, $buttonColor 50%, $bg 100%);
    }
    &:last-child {
        &::after {
            content: none;
        }
    }
    .stopType {
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
}

</style>