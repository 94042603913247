<template>
    <div class="userProfileMenu">
        <div class="userProfileGroup">
            <div class="userProfile" >
                <div class="profilePicture">{{ userImgText }}</div>

                <div class="content">
                    <div class="name textNoWrap">{{ user.name }}</div>
                    <div class="position textNoWrap">{{ user.username }}</div>
                </div>
            </div>

            <div class="dropDownLogOut">
                <a href="" @click.prevent="logOut">
                    <div class="icon"><i class="fas fa-sign-out-alt"></i></div>
                    <span>Log out</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
    export default {
        computed: {
            ...mapGetters(['user']),
            apiURL() {
                return process.env.VUE_APP_API_URL;
            },
            userImgText() {
                return this.user.name[0];
            }
        },
        methods: {
            redirectToProfile() {
                this.$router.push({ path: `/app/profile/${this.user.username}` });
            },
            logOut() {
                localStorage.removeItem("token");
                this.$store.commit('token', null);
                this.$store.commit('user', null);
            }
        },
        
    }
</script>

<style lang="scss" scoped>


.userProfileGroup {
    display: grid;
    row-gap: 20px;
    cursor: pointer;
    position: relative;
    padding: 10px;
    padding-right: 20px;
    max-width: 350px;
    &:hover {
        .dropDownLogOut {
            opacity: 1;
            pointer-events: auto;
        }
    }
}

.dropDownLogOut {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    opacity: 0;
    background: $bgSecondary;
    box-shadow: $boxShadow;
    padding: 100px 10px 10px 10px;
    pointer-events: none;
    transition: ease 0.3s;
    border-radius: $borderRadius;
    display: grid;
    row-gap: 5px;
    a {
        display: grid;
        align-items: center;
        grid-template-columns: 40px auto;
        border-radius: $borderRadius;
        color: #909090;
        transition: ease 0.5s;
        border: 1px solid transparent;
        .icon {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        span {
            overflow: hidden;
            transition: ease 0.5s;
            padding: 0 20px;
        }

        &:hover, &.router-link-exact-active {
            color: $buttonColor;
            background: $bg;
            border: 1px solid $borderColor;
        }
    }
}



.userProfile {
    position: relative;
    display: grid;
    grid-template-columns: 60px minmax(0, 1fr);
    align-items: center;
    column-gap: 20px;
    z-index: 2;
    .content {
        display: grid;
        row-gap: 5px;
        cursor: pointer;
        user-select: none;
    }
    .name {
        font-weight: 500;
        color: $text;
        font-size: 18px;
    }
}


.profilePicture {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 3px solid $buttonColor;
    display: flex;
    background: $bg;
    justify-content: center;
    align-items: center;
    color: $buttonColor;
    font-weight: bold;
    font-size: 20px;
}

.profileBtns {
    width: 50px;
    height: 50px;
    position: relative;
    button {
        width: 100%;
        height: 100%;
        background: transparent;
        border: 0;
        font-size: 20px;
    }
}

</style>