<template>
    <div class="gridHolder">

        <div class="file" v-if="selectedFile">
            <div class="name textNoWrap">{{ selectedFile.name }}</div>
            <div class="size">{{ formatBytes(selectedFile.size, 1) }}</div>

            <div class="input">
                <select v-model="type" class="">
                    <option :key="key" :value="item" v-for="(item, key) of types">{{ item }}</option>
                </select>
            </div>

            <button class="default small" @click="uploadFile" :disabled="loading">Upload</button>
            <div class="anotherFile"><a href="" @click.prevent="selectAnotherFile">Select another</a></div>
        </div>

        <div v-else>
            <button class="default small" @click="openFileSelector">Select file</button>
        </div>

        <input type="file" @change="fileSelected" ref="fileUploader" hidden>
    </div>
</template>

<script>
import axios from 'axios'

    export default {
        props: ['options'],
        data() {
            return {
                loadId: this.options.loadId || null,
                selectedFile: null,
                type: 'BOL',
                types: ['BOL', 'POD', 'Other'],
                loading: false
            }
        },
        methods: {
            async uploadFile() {
                this.loading = true;
                let body = {
                    type: this.type,
                    load: this.loadId
                };

                var formData = new FormData();
                formData.append("document", this.selectedFile);
                formData.append("body", JSON.stringify(body));

                await axios.post('/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(data => {
                    this.$hideModalSimple(true);
                }).catch(e => {
                    alert("Something went wrong. Please, try again!");
                });

                this.loading = false;

            },
            formatBytes(a,b=2){if(!+a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return`${parseFloat((a/Math.pow(1024,d)).toFixed(c))} ${["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]}`},
            openFileSelector() {
                this.$refs['fileUploader'].click();
            },
            fileSelected(ev) {
                if(ev.target.files[0]) {
                    this.selectedFile = ev.target.files[0];
                    ev.target.value = '';
                }
            },
            selectAnotherFile() {
                this.selectedFile = null;
                this.openFileSelector();
            }
        },
    }
</script>

<style lang="scss" scoped>
.gridHolder {
    display: grid;
    row-gap: 20px;
    justify-content: center;
}

.file {
    display: grid;
    justify-content: center;
    max-width: 220px;
    padding: 30px;
    row-gap: 10px;
    .name {
        text-align: center;
        font-weight: bold;
    }
    .size {
        text-align: center;
        opacity: 0.85;
    }
}

.anotherFile {
    display: flex;
    justify-content: center;
}
</style>