<template>
    <div class="fullPageForm">

        <div class="loginFormHolder">
            <div class="loaderHolder" v-if="isLoading(['handleLogin'])"><PageLoader/></div>
            <form @submit.prevent="handleSubmit" class="form">
                <div class="logoHolder"><img src="/logo.png" width="200"></div>
                <div class="loginTitle">Log in</div>
                <Input v-model="form.email" inputName="email" :error="errors.email" name="Email address" type="text" placeholder="Enter email address"/>
                <Input v-model="form.password" inputName="password" :error="errors.password" name="Password" type="password" placeholder="Password"/>
                <button type="submit" class="default">Log in</button>
                <div class="forgotPasswordLink"><router-link to="/restore">Forgot your password?</router-link></div>

                <div class="downloadAppsHolder">
                    <a href="https://apps.apple.com/app/driver-dashboard/id1645504660" target="_blank"><img src="/images/app-store-badge.svg" height="44"></a>
                    <a href="https://play.google.com/store/apps/details?id=us.fldispatch.driver" target="_blank"><img src="/images/google-play-badge.svg" height="44"></a>
                </div>

            </form>
        </div>


        <div class="imageContenHolder">
            <div class="contentGrid">
                <img src="/images/logo-light.png" width="200">
                <div class="largeText">Welcome back</div>
                <div class="smallText">Driver Dashboard provides access to all your loads and invoices.</div>
            </div>
        </div>

    </div>
</template>

<script>

    export default {
        title: "Log in",
        data() {
            return {
                form: {
                    email: '',
                    password: '',
                },
                errors: {},
            }
        },
        methods: {
            handleSubmit() {
                this.errors = {};

                this.ajax('handleLogin', {
                    method: 'POST',
                    url: '/login',
                    data: this.form
                }, (err, body) => {

                    if(err) {
                        if(body.errors) {
                            this.errors = body.errors;
                        } else if(body.message) {
                            alert(body.message);
                        } else {
                            alert("Something went wrong. Please, try again!");
                        }
                        return;
                    }

                    if(body.token) {
                        // store token in app store
                        this.$store.commit('token', body.token);
                        localStorage.setItem("token", body.token);
                        location.reload();
                    } else {
                        alert("Something went wrong. Please, try again!");
                    }

                });

            }
        }
    }
</script>

<style lang="scss" scoped>
.form {
    display: grid;
    row-gap: 20px;
    width: 100%;
    max-width: 350px;
}

.fullPageForm {
    min-height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.loginFormHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    position: relative;
    z-index: 2;
    .loaderHolder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255,255,255,0.5);
        z-index: 3;
    }
}

.imageContenHolder {
    background-position: center;
    background-size: cover;
    background-image: url('/images/login-bg.jpg');
    border-left: 1px solid $borderColor;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 30px;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
    }
    .contentGrid {
        position: static;
        z-index: 2;
        display: grid;
        justify-content: center;
        justify-items: center;
        row-gap: 20px;
        text-align: center;
        .largeText {
            font-weight: 700;
            font-size: 36px;
            line-height: 54px;
            /* identical to box height */
            text-transform: uppercase;
            color: #FFFFFF;
            font-family: 'Poppins';
        }
        .smallText {
            max-width: 492px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-transform: capitalize;

            color: #FFFFFF;
        }
    }
}

.loginTitle {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 24px;
    position: relative;
    margin-bottom: 25px;
    z-index: 1;
    &::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 48px;
        height: 4px;
        background: $text;
        transform: translateY(10px);
    }
}

.forgotPasswordLink {
    text-align: center;
}


.logoHolder {
    margin: 0 auto;
}

@media only screen and (max-width: 992px) {
    .fullPageForm {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .imageContenHolder {
        display: none;
    }
    .loginFormHolder {
        width: 100%;
    }
}


.downloadAppsHolder {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 10px;
    justify-items: center;
    justify-content: center;
    margin-top: 20px;
}

</style>