<template>
    <div>

        <nav>
            <div class="navHolder">
                <div class="brand"><Logo/></div>

               

                <div class="navRight">

                    <div class="links">
                        <router-link to="/" @click="hideMenu">
                            <div class="icon"><i class="fa-solid fa-boxes-stacked"></i></div>
                            <span>Loads</span>
                        </router-link>
                    </div>

                    <UserProfileButton/>
                </div>

                <div class="mobileButton">
                    <button @click="showMobileMenu = true"><i class="fa-solid fa-bars"></i></button>
                </div>
                
            </div>
        </nav>


        <div class="mobileNavBg" :class="{ 'active' : showMobileMenu }" @click="hideMenu"></div>
        <div class="mobileNav" :class="{ 'active' : showMobileMenu }">
            
            <div class="mobileContent">

                <div class="profile">
                    <div class="image">AE</div>
                    <div class="profileName">{{ user.name }}</div>
                    <div class="profileEmail">{{ user.username }}</div>
                    <div>
                        <a href="" @click.prevent="logOut" class="logOutBtn">
                            <span>Log out</span>
                            <div class="icon"><i class="fas fa-sign-out-alt"></i></div>
                        </a>
                    </div>
                </div>

                <div class="links">
                    <router-link to="/" @click="hideMenu">
                        <div class="icon"><i class="fa-solid fa-boxes-stacked"></i></div>
                        <span>Loads</span>
                    </router-link>
                </div>

            </div>
            
            
            <button class="close" @click="showMobileMenu = false"><i class="fa-solid fa-xmark"></i></button>

        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import UserProfileButton from './UserProfileButton.vue'

    export default {
        components: {
            Logo,
            UserProfileButton
        },
        computed: {
            ...mapGetters(['user', 'navigation'])
        },
        data() {
            return {
                menuShown: false,
                darkModeOn: false,
                showMobileMenu: false
            }
        },
        methods: {
            logOut() {
                this.hideMenu();
                localStorage.removeItem("token");
                this.$store.commit('token', null);
                this.$store.commit('user', null);
            },
            hideMenu() { 
                this.menuShown = false;
                this.showMobileMenu = false;
            },
            switchMenuMode() {
                this.menuShown = !this.menuShown;
            },
            logOut() {
                localStorage.removeItem("token");
                this.$store.commit('token', null);
                this.$store.commit('user', null);
                this.$router.push({ path: '/' });
            },
            switchDarkMode() {
                this.darkModeOn = !this.darkModeOn;
            },
            checkDarkMode() {
                if(this.darkModeOn) {
                    document.body.classList.add('dark-theme');
                } else {
                    document.body.classList.remove('dark-theme');
                }
            }
        },
        mounted() {
            let darkModeOnOrOff = localStorage.getItem('app-dark-modeon');
            if(darkModeOnOrOff) {
                if(darkModeOnOrOff == 'true') this.darkModeOn = true;
                else this.darkModeOn = false;
            }
            this.checkDarkMode();
        },
        watch: {
            darkModeOn() {
                localStorage.setItem("app-dark-modeon", this.darkModeOn);
                this.checkDarkMode();
            }
        }
    }
</script>

<style lang="scss" scoped>

nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: $bgSecondary;
    border-bottom: 1px solid $borderColor;
    z-index: 99;
    padding: 0 20px;
}

.navHolder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .menu {
        margin-left: auto;
        display: flex;
        align-items: center;
        a {
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 20px;
            margin: 0 20px;
            color: $text;
            &:hover, &.router-link-exact-active {
                color: $buttonColor;
            }
        }
    }
}

.navRight {
    display: grid;
    height: 100%;
    align-items: center;
    column-gap: 20px;
    margin-left: auto;
    grid-template-columns: auto auto;
    .links {
        display: flex;
        align-items: center;
        a {
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 10px;
            margin: 0 25px;
            color: $text;
            transition: ease 0.3s;
            font-size: 14px;
            &:hover, &.router-link-exact-active  {
                color: $buttonColor;
            }
        }
    }
}

.logoHolder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.mobileButton {
    display: none;
    button {
        width: 64px;
        height: 64px;
        border: 0;
        background: transparent;
        font-size: 20px;
    }
}


.mobileNavBg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    transition: ease 0.3s;
    &.active {
        opacity: 1;
        pointer-events: auto;
    }
}

.mobileNav {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 450px;
    height: 100%;
    border-left: 1px solid $borderColor;
    z-index: 100;
    background: $bg;
    transform: translateX(100%);
    display: none;
    transition: ease 0.3s;
    z-index: 100;
    &.active {
        transform: translateX(0);
    }

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 64px;
        height: 64px;
        border: 0;
        background: transparent;
        font-size: 20px;
    }

    .mobileContent {
        display: grid;
        row-gap: 30px;
    }

    .profile {
        display: grid;
        justify-content: center;
        justify-items: center;
        row-gap: 20px;
        padding: 30px;
        border-bottom: 1px solid $borderColor;
        background: $bgSecondary;
        .image {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            border: 3px solid $buttonColor;
            display: flex;
            background: $bg;
            justify-content: center;
            align-items: center;
            color: $buttonColor;
            font-weight: bold;
            font-size: 30px;
        }
        .profileName {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 16px;
        }
    }

    .links {
        padding: 20px;
        display: grid;
        row-gap: 10px;
        a {
            display: grid;
            align-items: center;
            grid-template-columns: 40px auto;
            padding: 0 10px;
            border-radius: $borderRadius;
            color: #909090;
            transition: ease 0.5s;
            height: 54px;
            .icon {
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            span {
                overflow: hidden;
                transition: ease 0.5s;
                padding: 0 20px;
            }

            &:hover, &.router-link-exact-active  {
                color: $buttonColor;
                background: $bgSecondary;
                box-shadow: $boxShadow;
            }
        }
    }

}


@media only screen and (max-width: 992px) {
    
    .mobileButton {
        display: block;
    }
    .navRight {
        .links {
            display: none;
        }
    }
    .mobileNav {
        display: block;
    }

}

@media only screen and (max-width: 700px) {
    .navRight {
        display: none;
    }
}


.logOutBtn {
    display: grid;
    align-items: center;
    grid-template-columns: auto 40px;
    border-radius: $borderRadius;
    color: #909090;
    transition: ease 0.5s;
    border: 1px solid $borderColor;
    .icon {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    span {
        overflow: hidden;
        transition: ease 0.5s;
        padding: 0 20px;
    }

    &:hover {
        color: $buttonColor;
        background: $bg;
    }
}
</style>