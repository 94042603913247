<template>
    <div class="chat">
        <div class="messagesContent">
            <div class="messages" ref="MessagesContainer">
                <Message :key="key" v-for="(message, key) of messages" :message="message"/>
            </div>
        </div>
        <div class="inputField">
            <textarea placeholder="Write a message..." :disabled="isLoading(['HandleSendMessage'])" v-model="message"></textarea>
            <button class="default" @click="sendMessage" :disabled="isLoading(['HandleSendMessage'])"><i class="fa-solid fa-paper-plane"></i></button>
        </div>
    </div>
</template>

<script>
import Message from './Message.vue'

    export default {
        components: {
            Message,
        },
        props: ['options'],
        data() {
            return {
                loadId: this.options.loadId || null,
                messages: [],
                message: '',
                snap: true
            }
        },
        mounted() {
            this.getMessages();

            // chat messages hehe
            this.$WebSocket.on('LoadChatMessage', (data) => {
                if(data.loadId !== this.loadId) return;
                this.addMessageToTheChat(data);
            });

            this.snapToBottomListener();
        },
        methods: {
            snapChatToBottom() {
                let chat = this.$refs['MessagesContainer'];
                setTimeout(() => {
                    chat.scrollTo(0, chat.scrollHeight);
                }, 100);
            },
            snapToBottomListener() {
                let chat = this.$refs['MessagesContainer'];

                let that = this;
                chat.onscroll = function(ev) {
                    if ((chat.scrollHeight - chat.scrollTop) <= (chat.clientHeight + 30)) {
                        that.snap = true;
                    } else {
                        that.snap = false;
                    }
                };

            },
            addMessageToTheChat(data) {
                for(const message of this.messages) {
                    if(message._id == data._id) return;
                }
                this.messages.push(data);
                if(this.snap) {
                    this.snapChatToBottom();
                }
            },
            getMessages() {
                this.ajax('HandleRetrieveMessages', {
                    url: `/loadMessages/GetLoadMessages/${this.loadId}`,
                    method: 'GET',
                }, (err, body) => {
                    if(!err) {
                        this.messages = body;
                        this.snapChatToBottom();
                    }
                });
            },
            sendMessage() {
                this.ajax('HandleSendMessage', {
                    url: `/load/SendMessage`,
                    method: 'POST',
                    data: {
                        loadId: this.loadId,
                        message: this.message.trim()
                    }
                }, (err, body) => {
                    if(!err) {
                        this.addMessageToTheChat(body);
                        this.message = '';
                    } else {
                        alert("Something went wrong. Please, try again!");
                    }
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
.chat {
    display: grid;
    row-gap: 20px;
}

.inputField {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 34px;
    align-items: end;
    column-gap: 20px;
    textarea {
        resize: none;
        max-height: 100px;
        min-height: 34px;
        border: 1px solid $borderColor;
        padding: 10px;
        border-radius: 5px;
    }
    button {
        width: 34px;
        height: 34px;
    }
}

.messagesContent {
    height: 300px;
    background: $bgSecondary;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    .messages {
        height: 100%;
        overflow-y: auto;
        padding: 20px 10px;
        display: grid;
        row-gap: 10px;
        align-items: start;
        align-content: start;
    }
}
</style>